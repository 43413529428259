import React, { ReactNode } from 'react';
import AppFooter from '../../AppFooter/AppFooter';
import './AppLayout.scss';

interface IProps {
  children: ReactNode;
}

class AppLayout extends React.Component<IProps> {

  render() {
    return (
      <div id="app-layout">
        {this.props.children}
        <AppFooter/>
      </div>
    );
  }
}

export default AppLayout;
