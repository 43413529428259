import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {MeDto} from "../../../core/models/dtos/me.dto";
import {setMe} from "../../../core/services/appService/setMe/actions";
import {IStore} from '../../../core/utilities/reducers';
import './HomePage.scss';
import phone from "../../../assets/images/iPhoneHome.png";
import logo from "../../../assets/images/logo.svg";
import gp from "../../../assets/images/google-play.svg";
import appstore from "../../../assets/images/app-store.svg";
import gBox from "../../../assets/images/GB_tri.svg";

interface IProps {
  setMe: (me?: MeDto) => void;
}

class HomePage extends Component<IProps> {
  render() {
    return (
      <div id='home-page' className='page'>
        <div className='page-content'>
          <div className='container'>
            <div className='content'>
              <img className='gb-tri' src={gBox} alt="gb-tri" />
              <img className='logo' src={logo} alt='logo'/>
              <h1 className='title'>
               MetaByte Cüzdanın!
              </h1>
              <span className='desc'>
              Kazan, biriktir!<br/> İstediğin zaman istediğin yerde kullan!
              </span>
              <div className='store-wrapper'>
                <a href="https://play.google.com/store/apps/details?id=com.solidict.catalog4p&hl=tr&gl=US" target='_blank' rel='noreferrer'><img className='store' src={gp} alt='GooglePlay'/></a>
                <a href="https://apps.apple.com/tr/app/kim-gb-i-ster/id1552759438?l=tr" target='_blank' rel='noreferrer'><img className='store' src={appstore} alt='AppStore'/></a>
              </div>
            </div>
            <img className='phone' src={phone} alt="phone" />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setMe,
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
