import React from "react";
import './AppFooter.scss';
import logo from "../../assets/images/4Plogo.svg"

interface IProps {
}

const AppFooter = (props: IProps) => {

  return (
    <div id="app-footer">
        <div className="content">
           <img src={logo} alt="footer-logo"/>
           <span className="text">©2022 4PLAY Dijital İşler Tic. Aş. Tüm hakları saklıdır.</span>
        </div>
    </div>
  );
};


export default (AppFooter);
